import React from 'react';
import { Link } from 'gatsby';

const getNextLink = (totalPageNumber, current, type) => {
  // 最終ページだったら非アクティブ状態
  if (current === totalPageNumber) return { isActive: false, to: '' }; // オブジェクトを返す

  // 最終ページ以外だったら1つ次のページのリンクを表示
  return { isActive: true, to: `/${type}/${current + 1}` };
};

const NextLink = ({ totalPageNumber, current, type }) => {
  // 分割代入
  const { isActive, to } = getNextLink(totalPageNumber, current, type);

  return (
    <li
      className={`c-pagination ${!isActive ? 'not-work' : ''}`}
      key="pager-older"
    >
      {isActive ? <Link to={to}>＞</Link> : <span>＞</span>}
    </li>
  );
};

export default NextLink;
