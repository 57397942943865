import React from 'react';
import styled from 'styled-components';
import Li from './Li';
import Skip from './Skip';
import PreviousLink from './PreviousLink';
import NextLink from './NextLink';

// ページネーションを表示させるコンポーネント
const Pagination = ({ totalPageNumber, current, type, path }) => {
  if (totalPageNumber <= 0) return '';

  console.log(`Pagination path: ${path}`);
  console.log(`Pagination totalPageNumber: ${totalPageNumber}`);
  console.log(`Pagination current: ${current}`);
  console.log(`Pagination type: ${type}`);

  // 総ページ数までの連番を配列に変換する
  const pageNumberArr = [...Array(totalPageNumber).keys()].map(i => i + 1);
  // 総ページング数
  const totalPagination = pageNumberArr.length;
  // ページネーションを表示させる最大数
  const MAX_PAGINATION = 6;

  if (totalPageNumber > 0) {
    return (
      <PagerWrapper>
        <PreviousLink
          current={current}
          type={type}
        />

        {pageNumberArr.map(i => {
          const path = i === 1 ? `/` : `/${type}/${i}`;

          if (totalPageNumber > MAX_PAGINATION) {
            if (current <= 3 || current === '') {
              // 現在のページが3ページ目より小さい場合
              if (totalPagination === i) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager-first`}
                  ></Li>
                );
              } else if (totalPagination - 1 === i) {
                // 最終ページの一つ前をSkip要素にする

                return <Skip key={i}></Skip>;
              } else if (i < MAX_PAGINATION) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              }
              // 現在のページが総ページ数の-3より大きい場合
            } else if (current >= totalPagination - 3) {
              if (i === 1) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              }

              if (i === 2) {
                return <Skip key={i}></Skip>;
              } else if (totalPagination - 5 < i) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              }
              // それ以外の場合
              // 総ページの-3より小さく、現在のページが3ページ目より大きい場合
            } else {
              if (i === 1) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              } else if (i === 2) {
                return <Skip key={i}></Skip>;
              } else if (current + 3 > i && current - 2 < i) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              } else if (totalPagination === i) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              } else if (totalPagination - 1 === i) {
                // 現在のページが総ページの一つ前のページだったら、その要素をスキップ要素に置き換える
                return <Skip key={i}></Skip>;
              }
            }
          } else {
            // 総ページ数が6より小さい場合はskip要素は表示されない
            return (
              <Li
                currentPageNumber={i}
                current={current === i}
                path={path}
                type={type}
                key={`pager${i}`}
              ></Li>
            );
          }
        })}
        <NextLink
          totalPageNumber={totalPageNumber}
          current={current}
          type={type}
        />
      </PagerWrapper>
    );
  } else {
    return '';
  }
};

export default Pagination;

const PagerWrapper = styled.div`
  text-align: center;

  // モバイルファースト: 基本のスタイリング
  .c-pagination span {
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    transition: all 0.3s;
    font-weight: bold;
    font-size: 1.1rem;
    list-style: none;
  }

  .c-pagination {
    display: none; // モバイルでは非表示にする

    &--current {
      display: inline-block; // 現在のページのみ表示
      background-color: #57a1d8;
      color: #fff;
    }
  }

  li {
    display: inline-block;
    margin-left: 8px;

    &:nth-of-type(1) {
      margin-left: 0px;
    }

    @media screen and (min-width: 1200px) {
      margin-left: 24px;
    }

    &:nth-of-type(1),
    &:last-child {
      display: inline-block; // 「前へ」「次へ」を表示
    }

    &.skip {
      display: none; // モバイルでは非表示
    }

    & a {
      display: block;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border: solid 1px #57a1d8;
      color: #57a1d8;
      text-align: center;
      transition: all 0.3s;
      font-weight: bold;
      font-size: 1.1rem;
      list-style: none;

      &:hover {
        color: #fff;
        background-color: #57a1d8;
      }
    }

    & span {
      display: block;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border: solid 1px #57a1d8;
      color: #fff;
      text-align: center;
      transition: all 0.3s;
      font-weight: bold;
      font-size: 1.1rem;
      list-style: none;
    }
  }

  // 画面幅が1200px以上のときのスタイリング
  @media screen and (min-width: 1200px) {
    .c-pagination {
      display: inline-block; // すべてのページネーションを表示
    }

    li {
      margin-left: 24px;

      &.skip {
        display: inline-block; // 「...」を表示
      }
    }
  }

  .not-work span {
    color: #57a1d8; // 「前へ」のスタイル
  }
`;
