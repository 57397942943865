import React from 'react';
import { Link } from 'gatsby';

/**
 * 一つ前に戻るページネーションのリンクまたは非アクティブ状態を決定する
 * @param {*} current
 * @param {*} type
 * @returns
 */
const getPreviousLink = (current, type) => {
  console.log(`current: ${current}, type: ${type}`);

  // 1ページ目だったら非アクティブ状態
  if (current === 1) return { isActive: false, to: '' }; // オブジェクトを返す

  // 2ページ目だったらトップページに戻るリンクを表示
  if (current === 2) return { isActive: true, to: '/' };

  // 2ページ目以降だった場合は、現在のページより一つ前のページのリンクを表示
  // Caution: 相対パスを指定すると、http://localhost/現在のページ/page/current - 1とパスが入ってしまうので以下のように絶対パスを指定すること
  // 誤: to={`${type}/${current - 1}`}
  // 正: to={`/${type}/${current - 1}`}
  return { isActive: true, to: `/${type}/${current - 1}` };
};

const PreviousLink = ({ current, type }) => {
  // 分割代入
  const { isActive, to } = getPreviousLink(current, type);

  return (
    <li
      className={`c-pagination ${!isActive ? 'not-work' : ''}`}
      key="pager-newer"
    >
      {isActive ? <Link to={to}>＜</Link> : <span>＜</span>}
    </li>
  );
};

export default PreviousLink;
