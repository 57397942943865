import { Link } from 'gatsby';
import React from 'react';

// リンクタグを表示させるコンポーネント
const Li = ({ currentPageNumber, current, path }) => {
  if (current) {
    return (
      <li className="c-pagination c-pagination--current">
        <span>{currentPageNumber}</span>
      </li>
    );
  }

  return (
    <li className="c-pagination">
      <Link to={path}>{currentPageNumber}</Link>
    </li>
  );
};

export default Li;
